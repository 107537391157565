export function seoTool( data = {}) {
  data.title = data.title || 'Free-Ringtones';
  data.metaDescription = data.metaDescription || 'Search free ringtones and personalize your phone. Free your Phone.';
  data.metaKeywords = data.metaKeywords || 'ringtones, free, phone, android, iphone, search, personalize, tuunes, download, play, android, ios, online';
  data.urlPage = data.urlPage || 'https://www.free-ringtones.com';
  
  document.title = data.title;
  // Meta data
  document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  document.querySelector('meta[name="keywords"]').setAttribute('content', data.metaKeywords);
  document.querySelector('meta[property="og:title"]').setAttribute('content', data.title);
  document.querySelector('meta[property="og:description"]').setAttribute('content', data.metaDescription);
  document.querySelector('meta[property="og:url"]').setAttribute('content', data.urlPage)
  
}