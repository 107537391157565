import React from "react";
import PropTypes from 'prop-types'; // npm install prop-types --save // define type for Props
import { Link } from "react-router-dom";
import playSymbol from '../../assets/images/play-symbol.svg';
import pauseSymbol from '../../assets/images/pause-symbol.svg';
import downloadSymbol from '../../assets/images/download-symbol.svg';

const ItemHome = (props) => {
    const show = props.show ? "member rounded-5 top-ring" : "member rounded-5 top-ring hid-xs";
    var n = props.title.split("[");
    n[0].split("(");
    const title = n[0];

    return(
        <div className={show}>
            <Link to={`/ringtone/${props.slug}`} className="no-ef"><h4>{title}</h4></Link>
            <span>{props.artist}</span>
            <p>{props.op !== "" ? `O.P. by ${props.op}`  : "" }</p>
            <img src={props.urlImage} alt="title-ringtone " className="img-fluid image-home" width={130} height={130} />
            <div className="contButtons rounded-5">
                <div className="play-btn" id={props.cod_id} onClick={() => props.playAction(props.cod_id)}>
                    <span><img src={props.playingId === props.cod_id ? pauseSymbol : playSymbol} alt={props.playingId === props.cod_id ? "pause" : "play"} width={22} height={22} /></span>
                    <p className="textBtn">{props.playingId === props.cod_id ? "Pause" : "Play"}</p>
                </div>
                <div className="download-btn" id={props.cod_id} onClick={() => props.downAction(props.cod_id)}>
                    <span><img src={downloadSymbol} alt="download" width={22} height={22} /></span>
                    <p className="textBtn">Download</p>
                </div>
            </div>
        </div>
    );
}

export default ItemHome;

// items props
ItemHome.propTypes = {
  cod_id: PropTypes.string,
  urlImage: PropTypes.string,
  playingId: PropTypes.string,
  op: PropTypes.string,
  artist: PropTypes.string,
  playAction: PropTypes.func,
  downAction: PropTypes.func
}