import React, { Fragment, useEffect } from "react";
import FooterSection from "../Components/FooterSection";
import { seoTool } from '../Helpers/seo';

const Imprint = (props) => {

    useEffect(() => {
            seoTool({
                title: 'Imprint - Free Ringtones',
                metaDescription: `Imprint Free Ringtones.com`,
                metaKeywords: `imprint,free,ringtones`,
                urlPage: window.location.href
            });
    },[]);

    return(
        <Fragment>
        <main id="main">
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <div className="space-10">
                        <p></p>
                    </div>
                    <h3>Free-ringtones — Imprint</h3>
                    <div className="space-10">
                        <p></p>
                    </div>
                    <h5>Provider<br/>Whitepoint GmbH</h5>
                    <p>Große Johannisstraße 13<br/>
                    20457 Hamburg, Germany<br/>
                    Phone: +49 (40) 349 99 741<br/>
                    Web: www.free-ringtones.com • service@free-ringtones.com<br/>
                    Represented By: Jan Kollmorgen, CEO<br/>
                    Registration Commercial Register: Amtsgericht Hamburg, HRB132308<br/>
                    VAT Registration Number: DE295304814<br/>
                    Responsible for Content: Jan Kollmorgen<br/><br/>
                    For service or any other inquiries please contact service@free-ringtones.com. We are here to help.<br/><br/></p>
                    <h5>Disclaimer</h5>
                    <p>Accountability for content<br/>
                    The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents’ accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this context, please note that we are accordingly not obliged to monitor merely the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).
                    </p>
                    <h5>Accountability for links</h5>
                    <p>Responsibility for the content of external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective link immediately.</p>
                    <h5>Copyright</h5><p>
                    Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law (§ 44a et seq. of the copyright law), every form of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. Individual reproductions of a work are allowed only for private use, so must not serve either directly or indirectly for earnings. Unauthorized utilization of copyrighted works is punishable (§ 106 of the copyright law).
                    </p>
                </div>
            </section>
        </main>
        <FooterSection />
        </Fragment>
    );
}
export default Imprint;
