import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/images/logo.svg'

const Header = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const openHandler = (evt) => {
        if(evt.target.id === "btn-mobile") {
            evt.preventDefault();
            if (isOpen === true) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        } else {
            if(isOpen === true) {
                setIsOpen(false);
            }
        }
    }
    
    return(
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center justify-content-between">

            <Link to="/" className="logo"><img src={logo} alt="Free-Ringtones.com" className="img-fluid" width={160} height={40}/></Link>

            <nav id="navbar" className={isOpen ? "navbar navbar-mobile" : "navbar" } onClick={openHandler}>
                <ul>
                    <li><Link className={ props.selected === "home" ? "nav-link scrollto active" : "nav-link scrollto" } to="/">Home</Link></li>
                    <li><a className="nav-link scrollto" href="https://apps.apple.com/us/app/tuunes-make-jam-ringtones/id1177574580" target="_blank" rel="noreferrer">App</a></li>
                    <li><a className="nav-link scrollto" href="https://www.tuunes.co/charts" target="_blank" rel="noreferrer">Charts</a></li>
                    <li><Link className={ props.selected === "free" ? "nav-link scrollto active" : "nav-link scrollto" } to="/find/ringtones">Free</Link></li>
                    <li><a className="nav-link scrollto " href="https://www.tuunes.co/" target="_blank" rel="noreferrer">More</a></li>
                </ul>
                <i id="btn-mobile" className="bi mobile-nav-toggle bi-list"></i>
            </nav>

            </div>
        </header>
    );
}

export default Header;