import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import fileNotFound from '../assets/lotties/not-found.json';

const NotFound = (props) => {

    const ref = useRef(false);

    useEffect(() => {
        console.log("Load");
        if(ref.current=== false) {
            lottie.loadAnimation({
                container: document.querySelector("#lottie-logo"),
                animationData: fileNotFound,
                renderer: "svg",
                loop: true,
                autoplay: true,
            });
            ref.current = true;
        } 
    }, [ref]);
    
    
    return(
        <main id="main">
            <div className='space'></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 flex-center-404">
                        <div id="lottie-logo" />
                        <div className="text-cont">
                            <h2>404</h2>
                            <h5>We can't find that page</h5>
                            <p>We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's behalf.</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default NotFound;