import React, { Fragment } from "react";

const ModalPopUp = (props) => {
    const show = props.show ? "modal" : "modal fade";
    const display = props.show ? "flex" : "none";

    return(
        <Fragment>
            <div className={show} tabIndex="-1" style={{display: display}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Ringtone only available in US</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeHandler}></button>
                    </div>
                    <div className="modal-body">
                        <p>This ringtone is currently not available in your country.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={props.closeHandler}>Alright</button>
                    </div>
                    </div>
                </div>
            </div>
    </Fragment>
    );
}
export default ModalPopUp;