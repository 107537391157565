import React from "react";
import { Link } from "react-router-dom";
import badgeApple from '../assets/images/apple_badge.svg';
import badgeGoogle from '../assets/images/google_badge.svg';

const FooterSection = () => {

     const handlerAppIos = (evt) => {
        evt.preventDefault();
        //window.location.href = "https://apps.apple.com/app/tuunes-klingelt%C3%B6ne-f%C3%BCr-iphone/id1177574580";
        window.open("https://apps.apple.com/app/tones-store-tuunes-ringtones/id1177574580", "_blank");
    }

    const handlerAppAndroid = (evt) => {
        evt.preventDefault();
        window.open("https://play.google.com/store/apps/details?id=co.tuunes.ringtones", "_blank");
    }

    return(
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h3>Are you looking for more ringtones?</h3>
                            <p>9.000+ already available</p>
                            <img className="badge-src img-fluid space-right" src={badgeApple} alt="Download on the App Store" onClick={handlerAppIos} />
                            <img className="badge-src img-fluid" src={badgeGoogle} alt="download on google play" onClick={handlerAppAndroid} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container footer-bottom clearfix">
                <div className="copyright">
                    &copy; Copyright <strong><span>free-ringtones.com</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    <Link to="/imprint" className="imprint">Imprint</Link>
                </div>
            </div>
        </footer>
    );
}
export default FooterSection;