import apiAxios from '../../Helpers/apiRequest';
import Ringtone from "../../Models/Ringtone";

export const FETCH_RINGTONES = 'FETCH_RINGTONES';
export const ERROR_FETCH_RINGTONES = 'ERROR_FETCH_RINGTONES';
export const LOADING_FETCH_RINGTONES = 'LOADING_FETCH_RINGTONES';
export const LOCATION_IP = 'LOCATION_IP';

export const getLocation = () => {
    return async dispatch => {
        apiAxios.get("/getLocation").then( (response) => {
            if (response.status !== 200) {
                console.log("[Redux-Main] - Request IP LOCATION Fail!");
            } else {
                let respData = response.data;
                dispatch({
                    type: LOCATION_IP,
                    location: respData.countryCode
                })
            }

        })
        .catch( (err) => {
            console.log("Errore request: ", err);
        })
    }
}

export const fetchRingtones = () => {
    return async dispatch => {
        var bodyData = new FormData();
        bodyData.append('apikey', 'getFree*!Ringtones2022T?!');
        dispatch({
            type: LOADING_FETCH_RINGTONES,
            loading: true
        });
        
        apiAxios({
            method: "post",
            url: "/getFreeRingtones",
            data: bodyData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then( response => {
            //console.log("response: ", response);
            if (response.status !== 200) {
                console.log("[Redux-Main] - Request Ringtones Fail!");
            } else {
                let respData = response.data;
                const dataRingtones = [];
                for (const key in respData) {
                    if (respData[key] !== null) {
                        dataRingtones.push(
                            new Ringtone(
                                respData[key].cod_id.toString(),
                                respData[key].file_image,
                                respData[key].title_tone,
                                respData[key].cod_artist,
                                respData[key].file_audio,
                                respData[key].cost,
                                respData[key].coins_requests,
                                respData[key].perform_artist,
                                respData[key].copyright,
                                respData[key].itunes_link,
                                respData[key].tuunes_link,
                                respData[key].ringtone_file,
                                respData[key].tags_new,
                                respData[key].name_artist,
                                respData[key].slug,
                                respData[key].exclude_country,
                                respData[key].is_in_promo,
                                respData[key].n_click
                            )
                        );
                    }
                }

                dispatch({
                    type: FETCH_RINGTONES,
                    ringtones: dataRingtones,
                    loading: false
                });
                
            }
        }).catch( error => {
            console.log("Catch Redux ringtones error: ", error);
            dispatch({
                type: ERROR_FETCH_RINGTONES
            })
        })
    }
}