import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import apiRequest from "../Helpers/apiRequest";
import fileDownload from 'js-file-download';
import { isIOS } from "react-device-detect";

import FooterHome from "../Components/FooterHome";
import HomeSection from "../Components/HomeSection";
import TopSection from "../Components/TopSection";
import ModalPopUp from "../Components/Segments/ModalPopUp";
import { seoTool } from '../Helpers/seo';
import { useNavigate } from "react-router-dom";

const Home = (props) => {
    let navigate = useNavigate();
    const locationIP = useSelector( state => state.main.locationIP ?? "");
        // PopUp Country not Available
    const [popUpShow, setPopUpShow] = useState(false);

    const audioRef = useRef(new Audio());
    const intervalRef = useRef(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [urlPlayIOS, setUrlPlayIOS] = useState("");
    const id = useRef("");
    const iosPlayer = useRef(document.getElementById("audioplayer"));

    useEffect(() => {
        seoTool({
            title: 'Free-Ringtones',
            metaDescription: 'Tired of listening to the same Ringtone? Search free ringtones and personalize your phone. Play & Download. Free your Phone.',
            metaKeywords: "ringtones, free, phone, android, iphone, search, personalize, tuunes, download, play, android, ios, online",
            urlPage: window.location.href
        });
    }, []);

    useEffect(() => {
        iosPlayer.current = document.getElementById("audioplayer");
        if (isPlaying) {
            if(isIOS) {
                iosPlayer.current.setAttribute("src", urlPlayIOS);
                iosPlayer.current.play();
                iosPlayer.current.addEventListener("ended", () => {
                    iosPlayer.current.removeEventListener("ended", () => console.log("removed"));
                    setIsPlaying(false);
                    id.current = 0;
                });
            } else {
                audioRef.current.play();
            }
        } else {
            if(isIOS) {
                iosPlayer.current.pause();
                iosPlayer.current.removeAttribute("src");
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying, urlPlayIOS]);

    useEffect( () => {
        //console.log("Duration changed");
    }, [duration]);

    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            if(isIOS) {
                iosPlayer.current.pause();
                iosPlayer.current.removeAttribute("src");
                iosPlayer.current.removeEventListener("ended", () => console.log("removed"));
            }
            audioRef.current.pause();
            clearInterval(intervalRef.current);
        }
    }, []);

    const startTimer = () => {
	  // Clear any timers already running
	  clearInterval(intervalRef.current);

	  intervalRef.current = setInterval(() => {
	    if (audioRef.current.ended) {
            setIsPlaying(false);
            audioRef.current.pause();
            id.current = 0;
            clearInterval(intervalRef.current);
	    } else {
           //console.log("Set Audio Progress, use timer!");
	    }
	  }, [1000]);
	}

    const playAudio = (item) => {
        let exclude = item.exclude_country.includes(locationIP); // eclude Country
        if (item.cod_id === id.current.toString() && isPlaying === true) {
            setIsPlaying(false);
            id.current = 0;
            clearInterval(intervalRef.current);
        } else {
            if(exclude) {
                setPopUpShow(true); // show popUp not available
            } else {
                if(isIOS) {
                    id.current = item.cod_id;
                    setIsPlaying(false);
                    iosPlayer.current.pause();
                    iosPlayer.current.removeAttribute("src");
                    //iosPlayer.current.removeAttribute()
                    setUrlPlayIOS("https://www.tuunes.co/app/upload/"+item.file_audio);
                    setIsPlaying(true);
                } else {
                    id.current = item.cod_id;
                    setIsPlaying(false);
                    audioRef.current.pause();
                    audioRef.current = new Audio("https://www.tuunes.co/app/upload/"+item.file_audio);
                    //audioRef.src = `https://www.tuunes.co/app/upload/${item.file_audio}`;
                    audioRef.current.addEventListener('loadeddata', () => {
                        // console.log("Audio Loaded");
                        let duration = audioRef.current.duration;
                        // console.log(duration);
                        setDuration(duration);
                        setIsPlaying(true);
                        startTimer();
                    });
                }
            }
        }

    }

    const downAction = (item) => {
        let exclude = item.exclude_country.includes(locationIP); // eclude Country
        if (exclude) {
            setPopUpShow(true); // show popUp not available
        } else {
            apiRequest.get(`downloadRingtone?cod_id=${item.cod_id}`, { 
                responseType: 'blob',
                headers: {
                    'Authorization': `Basic toooooken` 
                }
            })
            .then( response => {
                // console.log(response);
                fileDownload(response.data, `${item.title_tone} by ${item.name_artist}.mp3`);
            })
            .catch( error => {
                console.log("Error Download ringtone: ", error);
            });
        }
    }
    const closeHandler = (evt) => {
        evt.preventDefault();
        setPopUpShow(false);
    }

    return (
        <Fragment>
            <ModalPopUp show={popUpShow} closeHandler={closeHandler}/>
            <HomeSection nav={navigate} />
            <TopSection playAction={playAudio} downAction={downAction} playID={id.current.toString()}/>
            <FooterHome />
        </Fragment>
    );

}

export default Home;