import { FETCH_RINGTONES, LOADING_FETCH_RINGTONES, ERROR_FETCH_RINGTONES, LOCATION_IP } from '../actions/main';

const initialState = {
    ringtones: [],
    loading: false,
    error: false,
    locationIP: ""
};

const reducer = (state = initialState, action) => {
     switch (action.type) {
        case FETCH_RINGTONES:
            return {
                ...state,
                ringtones: action.ringtones,
                loading: action.loading
            };
        case LOADING_FETCH_RINGTONES:
            return {
                ...state,
                loading: action.loading
            };
        case ERROR_FETCH_RINGTONES:
            return {
                ...state,
                error: true
            };
        case LOCATION_IP:
            return {
                ...state,
                locationIP: action.location
            };
        default:
            return state;
    }
}

export default reducer;
