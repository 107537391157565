import React from "react";
import { isWebpSupported } from 'react-image-webp/dist/utils'; // https://www.npmjs.com/package/react-image-webp

import logoDiscover from '../assets/images/dicover_badge.svg';
import logoApple from '../assets/images/apple_badge.svg';
import logoGoogle from '../assets/images/google_badge.svg';
import logoHome from '../assets/images/hero-img.png';
import logoHomeWebp from '../assets/images/hero-img.webp'; // convert => https://convertio.co/png-webp/
//import { ReactComponent as LogoDiscover } from '../assets/images/logoDiscover.svg';

const HomeSection = (props) => {

    const handlerDiscover = (evt) => {
        evt.preventDefault();
        // navigate programmaticamente
        props.nav("/find/ringtones", { replace: false });
    }

    const handlerAppIos = (evt) => {
        evt.preventDefault();
        //window.location.href = "https://apps.apple.com/app/tuunes-klingelt%C3%B6ne-f%C3%BCr-iphone/id1177574580";
        window.open("https://apps.apple.com/app/tones-store-tuunes-ringtones/id1177574580", "_blank");
    }

    const handlerAppAndroid = (evt) => {
        evt.preventDefault();
        window.open("https://play.google.com/store/apps/details?id=co.tuunes.ringtones", "_blank");
    }

    return(
        <section id="hero" className="d-flex align-items-center space-hero">
            <div className="container">
            <div className="row">
                <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <h1>Tired of listening to the same ringtone over and over again?</h1>
                    <h2>You are in the right place, discover so many original <strong>free</strong> ringtones</h2>
                    <div className="d-flex custom-flex-xs">
                        <div className="col-sm-5 flexButtonContainer space-left">
                            <img fetchpriority="high" className="img-fluid img-custom badge-home-discover" src={logoDiscover} alt="logo discover" onClick={handlerDiscover} width={234} height={71} />
                        </div>
                        <div className="col-sm-6 flexButtonContainer">
                            <img className="img-fluid img-custom badge-home space-right" src={logoApple} alt="Download on the App Store" onClick={handlerAppIos} width={200} height={50} />
                            <img className="img-fluid img-custom badge-home" src={logoGoogle} alt="Download on the App Store" width={200} height={50} onClick={handlerAppAndroid} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 hero-img">
                    { isWebpSupported() ?
                        <img src={logoHomeWebp} fetchpriority="low" className="img-fluid animated" alt="free ringtone discover" width={752} height={800} loading="lazy" type="image/webp"/>
                        :
                        <img src={logoHome} fetchpriority="low" className="img-fluid animated" alt="free ringtone discover" width={752} height={800} loading="lazy" />
                    }
                </div>
            </div>
            </div>
        </section>
    );
}
export default HomeSection;