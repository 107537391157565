import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as Scroll from 'react-scroll';
import ReactGA from 'react-ga';
// actions
import * as mainAction from './store/actions/main';

// Views
import Home from './Pages/Home';
//import Ringtones from './Pages/Ringtones';
// import Detail from './Pages/Detail';
import Header from './Design/Header';
import Imprint from './Pages/Imprint';
import NotFound from './Pages/NotFound';

// esmpio di Loading async della pagina
const Ringtones = React.lazy( () => import('./Pages/Ringtones.js'));
const Detail = React.lazy( () => import('./Pages/Detail'));

const TRACKING_ID = "G-NZBFRXG5LT"; // YOUR_OWN_TRACKING_ID // MEASUREMENT ID => G-NZBFRXG5LT
ReactGA.initialize(TRACKING_ID);
// https://stackoverflow.com/questions/49279820/adding-google-analytics-to-react

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const dataRingtones = useSelector( state => state.main.ringtones);
  const [sectionSelected, setSectionSelected] = useState("home");
  let scroll = Scroll.animateScroll;

  useEffect(() => {
    if (dataRingtones.length <= 0) {
        // console.log("Status ENV: ", ENV().title); // status
        dispatch(mainAction.fetchRingtones()).then(); // () => console.log("[Action-Main] - fetch ringtones")
        dispatch(mainAction.getLocation()).then(); // get Location
    }
  }, [dispatch, dataRingtones]);

  useEffect( () => {
      // chiama questa funzione quando cambia la location, mostra questa location a video
      // console.log("Location changed: ", location); // questo mi mostra la location in cui si trova nella console!
      // passa nella header la sezione selezionata
      switch (location.pathname) {
        case "/":
          setSectionSelected("home");
          break;
        case "/find/ringtones":
          setSectionSelected("free");
          break;
        default:
          setSectionSelected("other");
            break;
      }

      // scroll to top on change location
      scroll.scrollToTop({ duration: 1 });

      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);

  }, [location, scroll]);

  useEffect( () => {

  }, []);

  return (
    <div className="App">
      <Header selected={sectionSelected} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/find/ringtones" element={ <Suspense><Ringtones /></Suspense> } />
        <Route path="/ringtone/:ringSlug" element={<Suspense><Detail /></Suspense>} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="*" element={ <NotFound /> } /> { /* NOT FOUND */}
      </Routes>
    </div>
  );
}

export default App;
