class Ringtone {
    
    constructor(cod_id, file_image, title_tone, cod_artist, file_audio, cost, coins_requests, perform_artist, 
        copyright, itunes_link, tuunes_link, ringtone_file, tags_new, name_artist, slug, exclude_country, is_in_promo, n_click) {
            this.cod_id = cod_id;
            this.file_image = file_image;
            this.title_tone = title_tone;
            this.cod_artist = cod_artist;
            this.file_audio = file_audio;
            this.cost = cost;
            this.coins_requests = coins_requests;
            this.perform_artist = perform_artist;
            this.copyright = copyright;
            this.itunes_link = itunes_link;
            this.tuunes_link = tuunes_link;
            this.ringtone_file = ringtone_file;
            this.tags_new = tags_new;
            this.name_artist = name_artist;
            this.slug = slug;
            this.exclude_country = exclude_country;
            this.is_in_promo = is_in_promo;
            this.n_click = n_click;
    }

}

export default Ringtone;
