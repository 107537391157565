import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

// redux, da verificare il combineReducers
import { configureStore } from '@reduxjs/toolkit' // configure store => versione nuova di createStore e combineStore => lo si fa qui automaticamente e si ha anche un middleware
import { Provider } from 'react-redux';
import mainReducer from './store/reducers/main';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/App.scss';
import './assets/css/style.css';

// PER CONSENTIRE A REDUX LE CHIAMATE ASYNC => applyMiddleware(ReduxThunk)
//const store = createStore(rootReducer, applyMiddleware(ReduxThunk)); /** composeWithDevTools() */
const store = configureStore({
  reducer: {
    main: mainReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }) // per disattivare il warning di salvare i dati nello store in maniera serializzata
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter  basename="/">
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
