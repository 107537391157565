import React from "react";
import ItemHome from "./Segments/ItemHome";
import { useSelector } from 'react-redux';


const TopSection = (props) => {
    const dataRingtones = useSelector( state => state.main.ringtones );
    const slicedArray = dataRingtones.slice(0, 5);

    const playAudioHandler = (id) => {
        const found = dataRingtones.find(element => element.cod_id === id);
        props.playAction(found);
    }
    const downloadAudioHandler = (id) => {
        const found = dataRingtones.find(element => element.cod_id === id);
        props.downAction(found);
    }

    return(
        <section id="team" className="team section-bg">
            <div className="container">

                <div className="section-title row">
                    <h2 className="col-12">Latest top free ringtones</h2>
                </div>

                <div className="row">
                    
                    <div className="d-flex justify-content-center custom-center">

                        { 
                            slicedArray.map( (item, index) => (
                                <ItemHome 
                                    key={item.cod_id}
                                    slug={item.tuunes_link}
                                    cod_id={item.cod_id}
                                    title={item.title_tone}
                                    artist={item.name_artist} 
                                    op={item.perform_artist}
                                    show={ index<3 }
                                    urlImage={"https://www.tuunes.co/app/upload/"+item.file_image}
                                    playAction={ (id) => playAudioHandler(id) }
                                    downAction={ (id) => downloadAudioHandler(id) }
                                    playingId={props.playID}
                                />
                            ) )
                        } 
                        
                    </div>



                </div>

            </div>
        </section>
    );
}
export default TopSection;